<template>
  <b-modal ref="modal" centered hide-footer size="lg" :title="title">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col lg="6" md="6">
            <inline-input
              v-model="data.last_name"
              :label="controls.last_name.label"
              :readonly="controls.last_name.readonly"
              :mode="mode"
            />
          </b-col>
          <b-col lg="6" md="6">
            <inline-input
              v-model="data.first_name"
              :label="controls.first_name.label"
              :readonly="controls.first_name.readonly"
              :mode="mode"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="6">
            <inline-input
              v-model="data.email1"
              :label="controls.email.label"
              :readonly="controls.email.readonly"
              :mode="mode"
            />
          </b-col>
          <b-col lg="6" md="6">
            <inline-input
              v-model="data.rolename"
              :label="controls.role.label"
              :readonly="controls.role.readonly"
              :mode="mode"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" md="12">
            <inline-input
              v-model="data.groups_string"
              :label="controls.groups.label"
              :readonly="controls.groups.readonly"
              :mode="mode"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <hr />
    <div class="form-row">
      <b-col sm="8"> </b-col>
      <b-col sm="4" class=" d-flex  justify-content-end">
        <b-button variant="outline-dark" class="m-1" @click="onCloseClick()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </b-col>
    </div>
  </b-modal>
</template>

<script>
import userManagementService from "@/services/user-management.service";

export default {
  props: {},
  name: "UserDetailsModal",
  components: {},
  data: function() {
    return {
      isLoading: false,
      title: "User details",
      mode: undefined,
      controls: {
        last_name: {
          id: "input:last_name",
          label: "Last Name",
          changed: false,
          readonly: true
        },
        first_name: {
          id: "input:first_name",
          label: "First Name",
          changed: false,
          readonly: true
        },
        email: {
          id: "input:email",
          label: "Email",
          changed: false,
          readonly: true
        },
        role: {
          id: "input:role",
          label: "Role",
          changed: false,
          readonly: true
        },
        groups: {
          id: "input:groups",
          label: "Groups",
          changed: false,
          readonly: true
        }
      },
      data: {
        last_name: "",
        first_name: "",
        rolename: "",
        email1: "",
        groups_string: ""
      }
    };
  },
  computed: {},
  async mounted() {},
  methods: {
    async show(payload) {
      console.log("payload", payload);
      this.mode = this.$constants.FORM_MODE.EDIT;

      this.data = await userManagementService.fetchUser(payload);
      console.log("data:", this.data);
      this.$refs.modal.show();
    },

    onCloseClick() {
      this.$refs.modal.hide();
    }
  },
  watch: {}
};
</script>

<style></style>
