var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "title": _vm.title
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.start_date.id,
      "value-single": _vm.data['Start Date'],
      "label": _vm.controls.start_date.label,
      "readonly": _vm.controls.start_date.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.end_date.id,
      "value-single": _vm.data['End Date'],
      "label": _vm.controls.end_date.label,
      "readonly": _vm.controls.end_date.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6"
    }
  }, [_c('inline-time-picker', {
    attrs: {
      "id": _vm.controls.start_time.id,
      "label": 'From time',
      "readonly": _vm.controls.start_time.readonly,
      "mode": _vm.mode
    },
    model: {
      value: _vm.data['Start Time'],
      callback: function callback($$v) {
        _vm.$set(_vm.data, 'Start Time', $$v);
      },
      expression: "data['Start Time']"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6"
    }
  }, [_c('inline-time-picker', {
    attrs: {
      "id": _vm.controls.end_time.id,
      "label": 'To time',
      "readonly": _vm.controls.end_time.readonly,
      "mode": _vm.mode
    },
    model: {
      value: _vm.data['End Time'],
      callback: function callback($$v) {
        _vm.$set(_vm.data, 'End Time', $$v);
      },
      expression: "data['End Time']"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4",
      "md": "12"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.group.id,
      "label": _vm.controls.group.label,
      "readonly": _vm.controls.group.readonly,
      "options": _vm.controls.group.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode
    },
    model: {
      value: _vm.data['Group'],
      callback: function callback($$v) {
        _vm.$set(_vm.data, 'Group', $$v);
      },
      expression: "data['Group']"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8",
      "md": "12"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.responsibles.id,
      "label": _vm.controls.responsibles.label,
      "readonly": false,
      "options": _vm.controls.responsibles.options,
      "allow-empty": true,
      "multiple": true,
      "mode": _vm.mode
    },
    model: {
      value: _vm.data['Responsibles'],
      callback: function callback($$v) {
        _vm.$set(_vm.data, 'Responsibles', $$v);
      },
      expression: "data['Responsibles']"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('inline-select', {
    ref: _vm.controls.account.id,
    staticStyle: {
      "width": "25em"
    },
    attrs: {
      "id": _vm.controls.account.id,
      "value": _vm.data['Account'],
      "label": _vm.controls.account.label,
      "readonly": _vm.controls.account.readonly,
      "options": _vm.controls.account.options,
      "loading": _vm.controls.account.loading,
      "async": true,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "async-search": _vm.onAccountSearch,
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "label": _vm.controls.notes.label,
      "readonly": _vm.controls.notes.readonly,
      "mode": _vm.mode,
      "is-text-area": true
    },
    model: {
      value: _vm.data['Notes'],
      callback: function callback($$v) {
        _vm.$set(_vm.data, 'Notes', $$v);
      },
      expression: "data['Notes']"
    }
  })], 1)], 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row"
  }, [_c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_vm.data.ID ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.onDeleteClick();
      }
    }
  }, [_vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('font-awesome-icon', {
    attrs: {
      "icon": "file"
    }
  }) : _vm._e(), _vm._v(" Delete ")], 1) : _vm._e()], 1), _c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "sm": "4"
    }
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.onSaveClick();
      }
    }
  }, [_vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('font-awesome-icon', {
    attrs: {
      "icon": "file"
    }
  }) : _vm._e(), _vm._v(" Save ")], 1), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.onCloseClick();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }