<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="client"
              dataset-name="production-schedule"
              :filters="filteringPanel.filters"
              :loaded="filteringPanel.loaded"
              :load-dictionaries="loadDictionaries"
              @search="getData"
              @change="filterData"
              @loaded="onFilteringPanelLoad"
              @dict-loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <b-tabs content-class="mt-3" justified>
          <b-tab title="Chart" active>
            <b-row>
              <b-col>
                <b-button-group>
                  <b-button
                    variant="outline-dark"
                    size="sm"
                    @click="addShift()"
                    title="New"
                  >
                    <font-awesome-icon icon="file" /> Create
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col>
                <vue-selecto
                  drag-container="#ganttastic-wrapper"
                  :selectable-targets="['.g-gantt-bar']"
                  :hit-rate="100"
                  :select-by-click="true"
                  :select-from-inside="true"
                  :ratio="0"
                  @select="onShiftsSelect"
                ></vue-selecto>

                <div id="ganttastic-wrapper">
                  <g-gantt-chart
                    :chart-start="ganttConfig.chartStart"
                    :chart-end="ganttConfig.chartEnd"
                    :grid="ganttConfig.grid"
                    :hide-timeaxis="ganttConfig.hideTimeaxis"
                    :push-on-overlap="ganttConfig.pushOnOverlap"
                    :hours="ganttConfig.hours"
                    :highlighted-hours="ganttConfig.highlightedHours"
                    :row-label-width="`${ganttConfig.rowLabelWidth}%`"
                    :row-height="ganttConfig.rowHeight"
                    :theme="ganttConfig.selectedTheme"
                    @dragend-bar="onBarDragEnd"
                    @dblclick-bar="onBarDblClick"
                    @contextmenu-bar="onBarContextMenu"
                  >
                    <g-gantt-row
                      v-for="row in rowList"
                      :key="row.title"
                      :label="row.label"
                      :bars="row.barList"
                      :highlight-on-hover="ganttConfig.highlightOnHover"
                      :class="row.class"
                      bar-start="start"
                      bar-end="end"
                    >
                      <template #label>
                        <div v-if="row.user_id == 0">{{ row.label }}</div>

                        <div
                          v-if="row.user_id !== 0"
                          class="row-label"
                          @click="onRowClick(row)"
                        >
                          <strong>
                            {{ row.label }}
                          </strong>
                        </div>
                      </template>
                      <template #bar-label="{bar}">
                        <div :id="bar.shiftId">
                          {{ bar.label }}
                        </div>
                      </template>
                    </g-gantt-row>
                  </g-gantt-chart>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Shift Time totals">
            <TimeTotals :raw-data="rawData"></TimeTotals>
          </b-tab>
          <b-tab title="Worked Time totals">
            <TimeTotals :raw-data="workedTimeData"></TimeTotals>
          </b-tab>
          <b-tab title="Shifts Related to Account">
            <ShiftsAccountsReport
              :raw-data="shiftsAccountsData"
            ></ShiftsAccountsReport>
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>

    <ContextMenu ref="contextMenu" style="z-index: 2;width: 200px">
      <template #default="slotProps">
        <b-list-group>
          <b-list-group-item
            style="padding: 0.5rem 1.25rem"
            v-for="item in contextMenu"
            button
            @click="item.method(slotProps.ctx)"
            :key="item.label"
            >{{ item.label }}</b-list-group-item
          >
        </b-list-group>
      </template>
    </ContextMenu>

    <ShiftDetailsModal
      ref="shift-details-modal"
      @updated="onShiftUpdate"
    ></ShiftDetailsModal>

    <UserDetailsModal ref="user-details-modal"></UserDetailsModal>
  </div>
</template>

<script>
import moment from "moment";
import { VueSelecto } from "vue-selecto";
import shiftServices from "@/services/TrackTime/shifts.service.js";
import userManagementServices from "@/services/user-management.service.js";

import ShiftDetailsModal from "./ShiftDetailsModal";
import UserDetailsModal from "./UserDetailsModal";
import ContextMenu from "@overcoder/vue-context-menu";
import FilteringPanel from "@/components/FilteringPanel";

import TimeTotals from "./TimeTotals";
import ShiftsAccountsReport from "./ShiftsAccountsReport";

import { mapState } from "vuex";
import { GGanttChart, GGanttRow } from "vue-ganttastic";

const shiftBarStyle = {
  color: "white",
  backgroundColor: "#01579b",
  //backgroundColor: "#01579bd9",
  opacity: 0.8,
  borderRadius: 0,
  handles: true,
  zIndex: 2
};
const workedTimeBarStyle = {
  color: "black",
  //backgroundColor: "#ffc10769",
  backgroundColor: "#ffc107",
  opacity: 0.5,
  borderRadius: 0,
  handles: false,
  immobile: true,
  zIndex: 1
};
const vacationsTimeBarStyle = {
  color: "black",
  //backgroundColor: "#ffc10769",
  backgroundColor: "#f86c6b",
  opacity: 0.5,
  borderRadius: 0,
  handles: false,
  immobile: true,
  zIndex: 1
};
export default {
  name: "ProductionSchedule",
  components: {
    FilteringPanel,
    GGanttChart,
    GGanttRow,
    ContextMenu,
    ShiftDetailsModal,
    VueSelecto,
    UserDetailsModal,
    TimeTotals,
    ShiftsAccountsReport
  },
  data() {
    return {
      contextMenu: [],
      ganttConfig: {
        chartStart: undefined,
        chartEnd: undefined,
        pushOnOverlap: false,
        grid: true,
        rowHeight: 40,
        rowLabelWidth: 15,
        hideTimeaxis: false,
        highlightOnHover: false,
        /*hours: [...Array(24).keys()],*/
        /*hours: [6, 12, 18, 24],*/
        hours: [],
        highlightedHours: [0, 12],
        showContextmenu: true,
        selectedTheme: "default"
      },
      rowList: [],
      filteringPanel: {
        loaded: false,
        selected: {},

        filters: [
          {
            type: "select",
            dataType: "string",
            title: "Groups",
            name: "groups",
            trackby: "id",
            label: "label",
            multiple: false,
            options: [],
            tooltip: "Groups",
            selected: {}
          },

          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "This month",
            title: "Period",
            name: "period",
            options: [],
            tooltip: "period"
          }
        ]
      },
      userGroups: [],
      dataSet: [],
      selectedShifts: [],
      rawData: [],
      workedTimeData: [],
      vacationsTimeData: [],
      shiftsAccountsData: []
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),

  async mounted() {
    console.log("profile", this.profile);
    //this.userGroups = await userManagementServices.fetchUserGroups();
    let mappedGroups = await userManagementServices.fetchGroupedUsers();

    this.userGroups = mappedGroups
      .filter(g => g.users.find(u => u.id == this.profile.data.id))
      .map(g => ({ id: g.id, label: g.group }));

    if (["H1", "H2"].includes(this.profile.data.role))
      this.userGroups = await userManagementServices.fetchUserGroups();

    this.getData();

    this.updateContextMenu();
  },
  methods: {
    updateContextMenu() {
      this.contextMenu = [];

      if (this.selectedShifts.length == 0)
        this.contextMenu.push({
          label: "Edit",
          method: this.onContextMenuEdit
        });

      this.contextMenu.push({
        label: "Clone",
        method: this.onContextMenuClone
      });

      this.contextMenu.push({
        label: "Clone to next day",
        method: this.onContextMenuClone2NextDay
      });
      this.contextMenu.push({
        label: "Clone to next week",
        method: this.onContextMenuClone2NextWeek
      });
      this.contextMenu.push({
        label: "Delete",
        method: this.onContextMenuDelete
      });
    },
    onShiftUpdate() {
      this.getData();
    },
    async loadDictionaries() {
      const groups = async () => {
        let mappedGroups = await userManagementServices.fetchGroupedUsers();

        this.userGroups = mappedGroups
          .filter(g => g.users.find(u => u.id == this.profile.data.id))
          .map(g => ({ id: g.id, label: g.group }));

        if (["H1", "H2"].includes(this.profile.data.role))
          this.userGroups = await userManagementServices.fetchUserGroups();

        this.filteringPanel.filters.find(
          f => f.name === "groups"
        ).options = this.userGroups;
      };
      Promise.all([groups()]).then(() => (this.filteringPanel.loaded = true));
    },
    onFilteringPanelStateChange() {},
    onFilteringPanelLoad() {
      this.filteringPanel.selected = this.$refs.filteringPanel.selected;
    },
    onCustomFilterSelected(e) {
      if (e.data) this.onSelectedExpression(e);
    },
    async onSelectedExpression() {},
    filterData(e) {
      this.filteringPanel.selected = e;
    },

    async getData() {
      let response = await shiftServices.fetchShifts(
        this.$refs.filteringPanel.selected
      );

      this.rawData = response;

      for (let row of this.rawData) {
        row[`Group`] = JSON.parse(row[`Group`]);
        row[`Responsibles`] = JSON.parse(row[`Responsibles`]);

        row[`Account`] = JSON.parse(row[`Account`]);
      }

      response = await shiftServices.fetchWorkedTime(
        this.$refs.filteringPanel.selected
      );

      this.workedTimeData = response;
      for (let row of this.workedTimeData) {
        row[`Responsibles`] = JSON.parse(row[`Responsibles`]);
      }
      response = await shiftServices.fetchVacationsTime(
        this.$refs.filteringPanel.selected
      );

      this.vacationsTimeData = response;
      for (let row of this.vacationsTimeData) {
        row[`Responsibles`] = JSON.parse(row[`Responsibles`]);
      }

      response = await shiftServices.fetchShiftsAccountsReport(
        this.$refs.filteringPanel.selected
      );

      this.shiftsAccountsData = response;

      this.drawChart();
    },
    drawChart() {
      let period = this.$refs.filteringPanel.selected.period;

      this.ganttConfig.chartStart = period.startDate;
      this.ganttConfig.chartEnd = moment(period.endDate)
        .add(1, "day")
        .format("YYYY-MM-DD");

      //select used groups list
      let groups = this.userGroups.filter(i =>
        [
          ...new Set([
            ...this.rawData.map(i => i["Group ID"]),
            ...this.workedTimeData.map(i => i["Group ID"]),
            ...this.vacationsTimeData.map(i => i["Group ID"])
          ])
        ].includes(i.id.toString())
      );
      console.log("this.rawData", this.rawData);
      this.rowList = [];

      groups.forEach(group => {
        this.rowList.push({
          user_id: 0,
          label: group.label.toUpperCase(),
          class: "group-row",
          barList: []
        });

        let users = [];

        for (let row of this.rawData.filter(i => i["Group ID"] == group.id)) {
          users = [...users, ...row[`Responsibles`]];
        }

        for (let row of this.workedTimeData.filter(
          i => i["Group ID"] == group.id
        )) {
          users = [...users, ...row[`Responsibles`]];
        }

        for (let row of this.vacationsTimeData.filter(
          i => i["Group ID"] == group.id
        )) {
          users = [...users, ...row[`Responsibles`]];
        }

        users.sort((a, b) => a.label.localeCompare(b.label));

        users.forEach(user => {
          if (!this.rowList.find(r => r.user_id == user.id)) {
            this.rowList.push({
              user_id: user.id,
              label: user.label,
              barList: []
            });
          }
        });
      });

      //add user shifts
      for (let row of this.rowList) {
        //find shifts where user [row of rowList] present in the responsibles array

        let userShifts = this.rawData.filter(i =>
          i["Responsibles"].find(j => j.id == row.user_id)
        );

        row.barList = userShifts.map(i => {
          let duration = moment
            .duration(moment(i["End"]).diff(i["Start"]))
            .asHours()
            .toFixed(1);

          let label = duration + "h";

          return {
            shiftId: i["ID"],
            start: i["Start"],
            end: i["End"],
            label: label,
            data: i,
            ganttBarConfig: { ...shiftBarStyle, ...{ bundle: i["ID"] } }
          };
        });

        //row.barList
        let workedTimebars = this.workedTimeData
          .filter(i => i["User ID"] == row.user_id)
          .map(j => ({
            trackId: j["ID"],
            start: j["Start"],
            end: j["End"],
            label: "",
            data: j,
            ganttBarConfig: { ...workedTimeBarStyle, ...{ bundle: j["ID"] } }
          }));

        //row.barList
        let vacationsTimebars = this.vacationsTimeData
          .filter(i => i["User ID"] == row.user_id)
          .map(j => ({
            trackId: j["ID"],
            start: j["Start"],
            end: j["End"],
            label: "",
            data: j,
            ganttBarConfig: { ...vacationsTimeBarStyle, ...{ bundle: j["ID"] } }
          }));

        row.barList = [...row.barList, ...workedTimebars, ...vacationsTimebars];
      }
    },
    onBarContextMenu(e) {
      e.event.preventDefault();

      if (e.bar.shiftId) this.$refs.contextMenu.open(e.event, e.bar);
    },
    onContextMenuEdit(e) {
      this.editShift(e.data);
    },
    async onContextMenuDelete(e) {
      let payload,
        confirmMessage = "";

      if (this.selectedShifts.length) {
        confirmMessage = `Are you sure want to delete selected ${this.selectedShifts.length} shifts?`;
        payload = this.selectedShifts;
      } else {
        let date = moment(e.start).format("YYYY-MM-DD");
        confirmMessage = `Are you sure want to delete shift on ${date}? There are ${e.data["Responsibles"].length} responsible employees`;
        payload = [e.shiftId];
      }
      this.selectedShifts = [];
      let confirm = await this.$form.showConfirmation(confirmMessage);

      if (!confirm) return;

      await shiftServices.deleteShift(payload);

      this.getData();
    },
    async onContextMenuClone(e) {
      let payload = [];
      if (this.selectedShifts.length) payload = this.selectedShifts;
      else payload = [e.shiftId];

      this.$refs.contextMenu.close();

      await shiftServices.cloneShift(payload);

      this.selectedShifts = [];
      this.getData();
    },
    async onContextMenuClone2NextDay(e) {
      let payload = [];
      if (this.selectedShifts.length) payload = this.selectedShifts;
      else payload = [e.shiftId];

      this.$refs.contextMenu.close();

      await shiftServices.cloneShift2NextDay(payload);

      this.selectedShifts = [];
      this.getData();
    },
    async onContextMenuClone2NextWeek(e) {
      let payload = [];
      if (this.selectedShifts.length) payload = this.selectedShifts;
      else payload = [e.shiftId];

      this.$refs.contextMenu.close();

      let response = await shiftServices.cloneShift2NextWeek(payload);

      let clonedShifts = response.data;

      this.selectedShifts = [];

      await this.getData();

      if (clonedShifts.length == 1) {
        let shift = this.rawData.find(i => i.ID == clonedShifts[0]);

        this.$refs["shift-details-modal"].show(shift);
      }
    },
    onBarDblClick(e) {
      if (e.bar.shiftId) this.editShift(e.bar.data);
    },
    async onBarDragEnd(e) {
      let payload = {
        ID: e.bar.shiftId,
        "Start Date": e.bar.start.split(" ")[0],
        "Start Time": e.bar.start.split(" ")[1],
        "End Date": e.bar.end.split(" ")[0],
        "End Time": e.bar.end.split(" ")[1]
      };

      await shiftServices.saveShift(payload);

      this.getData();
    },
    addShift() {
      this.$refs["shift-details-modal"].show();
    },
    editShift(e) {
      this.$refs["shift-details-modal"].show(e);
    },

    onShiftsSelect(e) {
      this.selectedShifts = [
        ...new Set(
          e.selected
            .filter(i => i.children[0].children[0].id !== "")
            .map(i => i.children[0].children[0].id)
        )
      ];

      if (!e.selected.length && e.removed.length > 0) {
        this.selectedShifts = [
          ...new Set(e.removed.map(i => i.children[0].children[0].id))
        ];
      }

      e.added.forEach(el => {
        el.classList.add("selected");
      });
      e.removed.forEach(el => {
        el.classList.remove("selected");
      });

      this.updateContextMenu();
    },
    onRowClick(e) {
      this.$refs["user-details-modal"].show(e);
    }
  },
  watch: {
    selectedShifts() {
      this.updateContextMenu();
    }
  }
};
</script>

<style scoped>
::v-deep #g-gantt-chart {
  font-family: "Arial" !important;
}
::v-deep .g-gantt-row-label {
  border-bottom: 1px solid #e8e8e8;
  background-color: rgb(255 255 255 / 70%) !important;
}
::v-deep .g-gantt-bar-handle-left {
  width: 3px !important;
  border-radius: 0px !important;
}

::v-deep .g-gantt-bar-handle-right {
  width: 3px !important;
  border-radius: 0px !important;
}

::v-deep #g-timeaxis {
  box-shadow: none !important;
  border-bottom: 1px solid #e8e8e8;
}

.group-row {
  background-color: #e0e0e0;
  height: 20px !important;
}
::v-deep .selected {
  /*background-color: #01579bd9 !important;*/
  background-color: #039be5c9 !important;
}
.row-label {
  cursor: pointer;
}
</style>
