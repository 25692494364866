var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "title": _vm.title
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "label": _vm.controls.last_name.label,
      "readonly": _vm.controls.last_name.readonly,
      "mode": _vm.mode
    },
    model: {
      value: _vm.data.last_name,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "last_name", $$v);
      },
      expression: "data.last_name"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "label": _vm.controls.first_name.label,
      "readonly": _vm.controls.first_name.readonly,
      "mode": _vm.mode
    },
    model: {
      value: _vm.data.first_name,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "first_name", $$v);
      },
      expression: "data.first_name"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "label": _vm.controls.email.label,
      "readonly": _vm.controls.email.readonly,
      "mode": _vm.mode
    },
    model: {
      value: _vm.data.email1,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "email1", $$v);
      },
      expression: "data.email1"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "label": _vm.controls.role.label,
      "readonly": _vm.controls.role.readonly,
      "mode": _vm.mode
    },
    model: {
      value: _vm.data.rolename,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "rolename", $$v);
      },
      expression: "data.rolename"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "label": _vm.controls.groups.label,
      "readonly": _vm.controls.groups.readonly,
      "mode": _vm.mode
    },
    model: {
      value: _vm.data.groups_string,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "groups_string", $$v);
      },
      expression: "data.groups_string"
    }
  })], 1)], 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row"
  }, [_c('b-col', {
    attrs: {
      "sm": "8"
    }
  }), _c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "sm": "4"
    }
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.onCloseClick();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }