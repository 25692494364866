var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-table-simple', {
    attrs: {
      "hover": "",
      "small": "",
      "caption-top": "",
      "responsive": "",
      "bordered": ""
    }
  }, [_c('b-thead', {
    attrs: {
      "head-variant": "dark"
    }
  }, [_c('b-tr', _vm._l(_vm.table.headers, function (header, index) {
    return _c('b-th', {
      key: index
    }, [_vm._v(" " + _vm._s(header) + " ")]);
  }), 1)], 1), _c('b-tbody', _vm._l(_vm.table.rows, function (row) {
    return _c('b-tr', {
      key: row.id
    }, [_c('b-td', [_c('b-link', {
      key: "r-".concat(_vm.index),
      attrs: {
        "href": "".concat(_vm.$client_url, "/#/relationship-management/account/view/").concat(row['Account ID'])
      }
    }, [_vm._v(" " + _vm._s(row["Account Name"]) + " ")])], 1), _c('b-td', [_vm._v(" " + _vm._s(row["Start Date"]) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(row["User Name"]) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(row["Start Time"]) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(row["End Time"]) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(row["Shift Hours"]) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(row["Track Time"]) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(row["Trackout Time"]) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(row["Worked Hours"]) + " ")]), row['Activity Subject'] ? _c('b-td', [_c('b-link', {
      attrs: {
        "href": "".concat(_vm.$client_url, "/#/account-touchpoints/activity/view/").concat(row['Activity ID'])
      }
    }, [_vm._v(" " + _vm._s(row["Activity Subject"]) + " ")])], 1) : _c('b-td', [_c('b-button', {
      staticClass: "btn btn-outline-dark",
      attrs: {
        "size": "sm",
        "variant": "light",
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.addActivity(row);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-toolbar fa-plus"
    }), _vm._v(" Create ")])], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }