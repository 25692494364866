import { api } from "@/shared/api";

const shifts = {
  fetchShift: async id => {
    let response = await api.get(`timetracker/shifts/${id}`);

    return response;
  },
  fetchShifts: async payload => {
    let response = await api.post(`timetracker/shifts`, payload);

    return response;
  },
  saveShift: async payload => {
    console.log("saveShift", payload);

    let url = payload.ID
      ? `timetracker/shifts/${payload.ID}`
      : `timetracker/shifts`;

    return api.put(url, payload);
  },
  deleteShift: async payload => {
    console.log("deleteShift", payload);
    return api.delete(`timetracker/shifts`, payload);
  },
  cloneShift: async payload => {
    return api.post(`timetracker/shifts/clone`, payload);
  },
  cloneShift2NextDay: async payload => {
    return api.post(`timetracker/shifts/clone-2-nextday`, payload);
  },
  cloneShift2NextWeek: async payload => {
    return api.post(`timetracker/shifts/clone-2-nextweek`, payload);
  },
  fetchWorkedTime: async payload => {
    let response = await api.post(`timetracker/shifts/worked-time`, payload);
    return response;
  },
  fetchVacationsTime: async payload => {
    let response = await api.post(`timetracker/shifts/vacations-time`, payload);
    return response;
  },

  fetchShiftsAccountsReport: async payload => {
    let response = await api.post(
      `timetracker/shifts/shifts-activites`,
      payload
    );

    return response;
  }
};

export default shifts;
