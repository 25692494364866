var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "client",
      "dataset-name": "production-schedule",
      "filters": _vm.filteringPanel.filters,
      "loaded": _vm.filteringPanel.loaded,
      "load-dictionaries": _vm.loadDictionaries
    },
    on: {
      "search": _vm.getData,
      "change": _vm.filterData,
      "loaded": _vm.onFilteringPanelLoad,
      "dict-loaded": _vm.onFilteringPanelLoad,
      "custom-filter-selected": _vm.onCustomFilterSelected,
      "state-changed": _vm.onFilteringPanelStateChange
    }
  })], 1)], 1), _c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Chart",
      "active": ""
    }
  }, [_c('b-row', [_c('b-col', [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "size": "sm",
      "title": "New"
    },
    on: {
      "click": function click($event) {
        return _vm.addShift();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "file"
    }
  }), _vm._v(" Create ")], 1)], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('vue-selecto', {
    attrs: {
      "drag-container": "#ganttastic-wrapper",
      "selectable-targets": ['.g-gantt-bar'],
      "hit-rate": 100,
      "select-by-click": true,
      "select-from-inside": true,
      "ratio": 0
    },
    on: {
      "select": _vm.onShiftsSelect
    }
  }), _c('div', {
    attrs: {
      "id": "ganttastic-wrapper"
    }
  }, [_c('g-gantt-chart', {
    attrs: {
      "chart-start": _vm.ganttConfig.chartStart,
      "chart-end": _vm.ganttConfig.chartEnd,
      "grid": _vm.ganttConfig.grid,
      "hide-timeaxis": _vm.ganttConfig.hideTimeaxis,
      "push-on-overlap": _vm.ganttConfig.pushOnOverlap,
      "hours": _vm.ganttConfig.hours,
      "highlighted-hours": _vm.ganttConfig.highlightedHours,
      "row-label-width": "".concat(_vm.ganttConfig.rowLabelWidth, "%"),
      "row-height": _vm.ganttConfig.rowHeight,
      "theme": _vm.ganttConfig.selectedTheme
    },
    on: {
      "dragend-bar": _vm.onBarDragEnd,
      "dblclick-bar": _vm.onBarDblClick,
      "contextmenu-bar": _vm.onBarContextMenu
    }
  }, _vm._l(_vm.rowList, function (row) {
    return _c('g-gantt-row', {
      key: row.title,
      class: row.class,
      attrs: {
        "label": row.label,
        "bars": row.barList,
        "highlight-on-hover": _vm.ganttConfig.highlightOnHover,
        "bar-start": "start",
        "bar-end": "end"
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [row.user_id == 0 ? _c('div', [_vm._v(_vm._s(row.label))]) : _vm._e(), row.user_id !== 0 ? _c('div', {
            staticClass: "row-label",
            on: {
              "click": function click($event) {
                return _vm.onRowClick(row);
              }
            }
          }, [_c('strong', [_vm._v(" " + _vm._s(row.label) + " ")])]) : _vm._e()];
        },
        proxy: true
      }, {
        key: "bar-label",
        fn: function fn(_ref) {
          var bar = _ref.bar;
          return [_c('div', {
            attrs: {
              "id": bar.shiftId
            }
          }, [_vm._v(" " + _vm._s(bar.label) + " ")])];
        }
      }], null, true)
    });
  }), 1)], 1)], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Shift Time totals"
    }
  }, [_c('TimeTotals', {
    attrs: {
      "raw-data": _vm.rawData
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Worked Time totals"
    }
  }, [_c('TimeTotals', {
    attrs: {
      "raw-data": _vm.workedTimeData
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Shifts Related to Account"
    }
  }, [_c('ShiftsAccountsReport', {
    attrs: {
      "raw-data": _vm.shiftsAccountsData
    }
  })], 1)], 1)], 1)], 1), _c('ContextMenu', {
    ref: "contextMenu",
    staticStyle: {
      "z-index": "2",
      "width": "200px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(slotProps) {
        return [_c('b-list-group', _vm._l(_vm.contextMenu, function (item) {
          return _c('b-list-group-item', {
            key: item.label,
            staticStyle: {
              "padding": "0.5rem 1.25rem"
            },
            attrs: {
              "button": ""
            },
            on: {
              "click": function click($event) {
                return item.method(slotProps.ctx);
              }
            }
          }, [_vm._v(_vm._s(item.label))]);
        }), 1)];
      }
    }])
  }), _c('ShiftDetailsModal', {
    ref: "shift-details-modal",
    on: {
      "updated": _vm.onShiftUpdate
    }
  }), _c('UserDetailsModal', {
    ref: "user-details-modal"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }