var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-table-simple', {
    attrs: {
      "hover": "",
      "small": "",
      "caption-top": "",
      "responsive": ""
    }
  }, [_c('b-thead', {
    attrs: {
      "head-variant": "dark"
    }
  }, [_c('b-tr', _vm._l(_vm.table.headers, function (header, index) {
    return _c('b-th', {
      key: "tt1-".concat(index)
    }, [_vm._v(" " + _vm._s(header) + " ")]);
  }), 1)], 1), _c('b-tbody', _vm._l(_vm.table.rows, function (row, index) {
    return _c('b-tr', {
      key: "tt2-".concat(index)
    }, [row.user_id ? _c('b-th', {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(row.label))]) : _c('b-th', {
      staticClass: "text-right",
      attrs: {
        "variant": "success"
      }
    }, [_vm._v(_vm._s(row.label))]), _vm._l(row.days, function (day, index) {
      return [row.user_id ? _c('b-td', {
        key: "tt4-".concat(index)
      }, [_vm._v(_vm._s(day.total))]) : _c('b-td', {
        key: "tt5-".concat(index),
        attrs: {
          "variant": "success"
        }
      }, [_c('strong', [_vm._v(_vm._s(day.total))])])];
    })], 2);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }