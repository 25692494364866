<template>
  <div>
    <b-table-simple hover small caption-top responsive bordered>
      <b-thead head-variant="dark">
        <b-tr>
          <b-th v-for="(header, index) in table.headers" :key="index">
            {{ header }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="row in table.rows" :key="row.id">
          <b-td>
            <b-link
              :key="`r-${index}`"
              :href="
                `${$client_url}/#/relationship-management/account/view/${row['Account ID']}`
              "
            >
              {{ row["Account Name"] }}
            </b-link>
          </b-td>
          <b-td>
            {{ row["Start Date"] }}
          </b-td>
          <b-td>
            {{ row["User Name"] }}
          </b-td>
          <b-td>
            {{ row["Start Time"] }}
          </b-td>
          <b-td>
            {{ row["End Time"] }}
          </b-td>
          <b-td>
            {{ row["Shift Hours"] }}
          </b-td>
          <b-td>
            {{ row["Track Time"] }}
          </b-td>
          <b-td>
            {{ row["Trackout Time"] }}
          </b-td>
          <b-td>
            {{ row["Worked Hours"] }}
          </b-td>
          <b-td v-if="row['Activity Subject']">
            <b-link
              :href="
                `${$client_url}/#/account-touchpoints/activity/view/${row['Activity ID']}`
              "
            >
              {{ row["Activity Subject"] }}
            </b-link>
          </b-td>
          <b-td v-else>
            <b-button
              class="btn btn-outline-dark"
              size="sm"
              variant="light"
              type="button"
              @click="addActivity(row)"
            >
              <i class="fa fa-toolbar fa-plus " /> Create
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
export default {
  props: {
    rawData: {
      type: Array,
      default: () => []
    }
  },
  name: "ShiftsAccounts",
  components: {},
  data: function() {
    return {
      table: {
        headers: [
          "Account",
          "Date",
          "User",
          "From",
          "To",
          "Shift Hours",
          "From",
          "To",
          "Worked Hours",
          "Activity"
        ],
        rows: []
      }
    };
  },
  computed: {},
  async mounted() {},
  methods: {
    prepareData(payload) {
      this.table.rows = payload;
    },
    addActivity(payload) {
      this.$router.push({
        name: "Activity submission",
        params: {
          action: "create",
          params: {
            account_id: payload["Account ID"],
            user_id: payload["User ID"],
            activity_date: payload["Start Date"]
          }
        }
      });
    }
  },
  watch: {
    rawData(newValue) {
      this.prepareData(newValue);
    }
  }
};
</script>

<style></style>
