<template>
  <b-modal ref="modal" centered hide-footer size="lg" :title="title">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col lg="6" md="6">
            <inline-date-picker
              :id="controls.start_date.id"
              :value-single="data['Start Date']"
              :label="controls.start_date.label"
              :readonly="controls.start_date.readonly"
              :mode="mode"
              @changed="updateDateField"
            />
          </b-col>
          <b-col lg="6" md="6">
            <inline-date-picker
              :id="controls.end_date.id"
              :value-single="data['End Date']"
              :label="controls.end_date.label"
              :readonly="controls.end_date.readonly"
              :mode="mode"
              @changed="updateDateField"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="6">
            <inline-time-picker
              :id="controls.start_time.id"
              v-model="data['Start Time']"
              :label="'From time'"
              :readonly="controls.start_time.readonly"
              :mode="mode"
            />
          </b-col>
          <b-col lg="6" md="6">
            <inline-time-picker
              :id="controls.end_time.id"
              v-model="data['End Time']"
              :label="'To time'"
              :readonly="controls.end_time.readonly"
              :mode="mode"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" md="12">
            <inline-select
              :id="controls.group.id"
              v-model="data['Group']"
              :label="controls.group.label"
              :readonly="controls.group.readonly"
              :options="controls.group.options"
              :allow-empty="false"
              :multiple="false"
              :mode="mode"
            />
          </b-col>
          <b-col lg="8" md="12">
            <inline-select
              :id="controls.responsibles.id"
              v-model="data['Responsibles']"
              :label="controls.responsibles.label"
              :readonly="false"
              :options="controls.responsibles.options"
              :allow-empty="true"
              :multiple="true"
              :mode="mode"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <inline-select
              style="width: 25em"
              :id="controls.account.id"
              :ref="controls.account.id"
              :value="data['Account']"
              :label="controls.account.label"
              :readonly="controls.account.readonly"
              :options="controls.account.options"
              :loading="controls.account.loading"
              :async="true"
              @async-search="onAccountSearch"
              :allow-empty="false"
              :multiple="false"
              :mode="mode"
              @changed="updateSelect"
              :required="false"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" md="12">
            <inline-input
              v-model="data['Notes']"
              :label="controls.notes.label"
              :readonly="controls.notes.readonly"
              :mode="mode"
              :is-text-area="true"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <hr />
    <div class="form-row">
      <b-col sm="8">
        <b-button
          v-if="data.ID"
          variant="danger"
          class="m-1"
          @click="onDeleteClick()"
        >
          <b-spinner v-if="isLoading" small type="grow" />
          <font-awesome-icon v-if="!isLoading" icon="file" /> Delete
        </b-button>
      </b-col>
      <b-col sm="4" class=" d-flex  justify-content-end">
        <b-button variant="outline-dark" class="m-1" @click="onSaveClick()">
          <b-spinner v-if="isLoading" small type="grow" />
          <font-awesome-icon v-if="!isLoading" icon="file" /> Save
        </b-button>

        <b-button variant="outline-dark" class="m-1" @click="onCloseClick()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </b-col>
    </div>
  </b-modal>
</template>

<script>
import InlineSelect from "@/components/InlineSelectMultiple";
import InlineDatePicker from "@/components/InlineDatePicker";
import InlineTimePicker from "@/components/InlineTimePicker";

import shiftServices from "@/services/TrackTime/shifts.service";
import userManagementService from "@/services/user-management.service";

import { mapState } from "vuex";

export default {
  props: {},
  name: "ShiftModal",
  components: {
    InlineSelect,
    InlineDatePicker,
    InlineTimePicker
  },
  data: function() {
    return {
      isLoading: false,
      title: "Shift details",
      mode: undefined,
      controls: {
        start_date: {
          id: "datepicker:start_date",
          label: "Start Date",
          changed: false,
          readonly: false
        },
        end_date: {
          id: "datepicker:end_date",
          label: "End Date",
          changed: false,
          readonly: false
        },
        start_time: {
          id: "timepicker:start_time",
          label: "From",
          changed: false,
          readonly: false
        },
        end_time: {
          id: "timepicker:end_time",
          label: "To",
          changed: false,
          readonly: false
        },
        group: {
          id: "select:group",
          label: "Group",
          options: [],
          changed: false,
          readonly: false
        },

        responsibles: {
          id: "select:responsibles",
          label: "Responsibles",
          options: [],
          changed: false,
          readonly: true
        },

        notes: {
          id: "input:notes",
          label: "Notes",
          changed: false,
          readonly: false
        },
        account: {
          id: "select:account",
          label: "Account",
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        }
      },
      data: {},
      groupedUsers: []
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    }),
    isReadonly() {
      return this.data.returned_bottling_number !== undefined;
    }
  },
  async mounted() {
    /*
    userManagementService.fetchActiveUsers().then(response => {
      this.controls.responsibles.options = response;
    });
    userManagementService.fetchUserGroups().then(response => {
      this.controls.group.options = response;
    });
    */

    this.groupedUsers = await userManagementService.fetchGroupedUsers();

    this.controls.group.options = this.groupedUsers.map(i => ({
      id: i.id,
      label: i.group
    }));
  },
  methods: {
    onAccountSearch(payload) {
      let self = this;

      self.controls.account.loading = true;

      this.$api
        .post("dictionaries/accounts", {
          norestrict: true,
          query: payload.query,
          starts_with: payload.startsWith
        })
        .then(response => {
          self.controls.account.loading = false;

          self.controls.account.options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
    },
    updateField(_field, _value, _mode) {
      //console.log("_field, _value, _mode", _field, _value, _mode);
      if (_field == "amount" && _value) {
        var number = Number(_value.replace(",", ".").replace(/[^0-9.-]+/g, ""));
        _value = number;
      }

      let payload = {
        id: this.id,
        self: this,
        url: "shifts",
        field: _field,
        value: _value,
        mode: _mode
      };

      if (payload.field == "account") payload.field = "Account";
      console.log(payload);
      this.$form.updateField2(payload);
    },
    updateSelect(id, value) {
      this.updateField(id, value);
    },
    show(payload) {
      this.mode = payload
        ? this.$constants.FORM_MODE.EDIT
        : this.$constants.FORM_MODE.CREATE;

      this.data = Object.assign({}, payload);

      this.$refs.modal.show();
    },
    validateForm() {
      let errors = [];
      if (!this.data["Group"]) errors.push("Please select Group");
      if (!this.data["Start Date"]) errors.push("Please select Shift Date");
      if (!this.data["Start Time"]) errors.push("Please select Start Time");
      if (!this.data["End Time"]) errors.push("Please select End Time");
      if (
        !this.data["Responsibles"] ||
        (this.data["Responsibles"] && this.data["Responsibles"].length == 0)
      )
        errors.push("Please select responsible employees");

      if (errors.length) {
        this.$form.makeToastError(errors[0]);
        return false;
      }
      return true;
    },
    async onSaveClick() {
      if (!this.validateForm()) return;

      let response = await shiftServices.saveShift(this.data);

      this.$form.makeToastInfo(response.message);

      this.$emit("updated");
      this.$refs.modal.hide();
    },

    onCloseClick() {
      this.$refs.modal.hide();
    },
    updateDateField(e) {
      console.log(e);
      if (e.id == "start_date") this.data["Start Date"] = e.valueSingleAsString;

      if (e.id == "end_date") this.data["End Date"] = e.valueSingleAsString;
    },
    async onDeleteClick() {
      let confirm = await this.$form.showConfirmation(
        `Are you sure want to delete this shift?`
      );

      if (!confirm) return;

      await shiftServices.deleteShift([this.data.ID]);

      this.$refs.modal.hide();

      this.$emit("updated");
    }
  },
  watch: {
    "data.Group"(newValue, oldValue) {
      if (newValue && newValue.id) {
        this.controls.responsibles.readonly = false;

        this.controls.responsibles.options = this.groupedUsers.find(
          i => i.id == newValue.id
        ).users;
      }

      if (newValue && oldValue && newValue.id !== oldValue.id) {
        this.data["Responsibles"] = [];
      }
    }
  }
};
</script>

<style></style>
