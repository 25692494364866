<template>
  <div>
    <b-table-simple hover small caption-top responsive>
      <b-thead head-variant="dark">
        <b-tr>
          <b-th v-for="(header, index) in table.headers" :key="`tt1-${index}`">
            {{ header }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(row, index) in table.rows" :key="`tt2-${index}`">
          <b-th v-if="row.user_id" class="text-right">{{ row.label }}</b-th>
          <b-th v-else variant="success" class="text-right">{{
            row.label
          }}</b-th>

          <template v-for="(day, index) in row.days">
            <b-td v-if="row.user_id" :key="`tt4-${index}`">{{
              day.total
            }}</b-td>
            <b-td v-else variant="success" :key="`tt5-${index}`">
              <strong>{{ day.total }}</strong>
            </b-td>
          </template>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import userManagementServices from "@/services/user-management.service.js";
import helpers from "@/shared/helpers";
import moment from "moment";

import { mapState } from "vuex";

export default {
  props: {
    rawData: {
      type: Array,
      default: () => []
    }
  },
  name: "ShiftTimeTotals",
  components: {},
  data: function() {
    return {
      userGroups: [],
      table: { headers: [], rows: [] }
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    })
  },
  async mounted() {
    let mappedGroups = await userManagementServices.fetchGroupedUsers();

    this.userGroups = mappedGroups
      .filter(g => g.users.find(u => u.id == this.profile.data.id))
      .map(g => ({ id: g.id, label: g.group }));

    if (["H1", "H2"].includes(this.profile.data.role))
      this.userGroups = await userManagementServices.fetchUserGroups();
  },
  methods: {
    prepareData() {
      let groups = this.userGroups.filter(i => {
        return [...new Set([...this.rawData.map(i => i["Group ID"])])].includes(
          i.id.toString()
        );
      });

      console.log("this.userGroups", this.userGroups);

      let daysList = [...new Set([...this.rawData.map(i => i["Start Date"])])];

      daysList.sort((a, b) => helpers.compareDate(a, b));

      this.table.headers = [...["User Name"], ...daysList, ...["Total"]];

      console.log("this.table:", this.table);

      console.log("groups:", groups);

      this.table.rows = [];
      groups.forEach(group => {
        //add group row
        this.table.rows.push({
          group_id: group.id,
          user_id: 0,
          label: group.label.toUpperCase(),
          days: [],
          total: 0
        });

        let users = [];

        //select group users
        for (let row of this.rawData.filter(i => i["Group ID"] == group.id)) {
          users = [...users, ...row[`Responsibles`]];
        }

        users.sort((a, b) => a.label.localeCompare(b.label));

        users.forEach(user => {
          //if user not added, calculate time totals and add it
          if (!this.table.rows.find(r => r.user_id == user.id)) {
            let days = [];

            daysList.forEach(d => {
              let rows = this.rawData.filter(
                r =>
                  r["Start Date"] == d &&
                  r["Responsibles"].find(u => u.id == user.id)
              );

              let today = moment().format("YYYY-MM-DD");

              let total = 0;

              rows.forEach(row => {
                //calculate day totals
                if (row && row["Start Date"] == row["End Date"]) {
                  total += moment(row["End"]).diff(
                    moment(row["Start"]),
                    "hours",
                    true
                  );
                }

                if (row && row["Start Date"] !== row["End Date"]) {
                  if (
                    new Date(row["Start Date"]) < new Date(today) &&
                    new Date(row["End Date"]) > new Date(today)
                  )
                    total += moment(today + " 23:59").diff(
                      moment(today + "00:00"),
                      "hours",
                      true
                    );

                  if (
                    new Date(row["Start Date"]) < new Date(today) &&
                    new Date(row["End Date"]) == new Date(today)
                  )
                    total += moment(row["End"]).diff(
                      moment(today + "00:00"),
                      "hours",
                      true
                    );

                  if (
                    new Date(row["Start Date"]) == new Date(today) &&
                    new Date(row["End Date"]) > new Date(today)
                  )
                    total += moment(today + " 23:59").diff(
                      moment(row["Start"]),
                      "hours",
                      true
                    );
                }
              });

              if (total) {
                total = total.toFixed(2);
              }
              let day = {
                date: d,
                total: total
              };

              days.push(day);
            });

            this.table.rows.push({
              group_id: group.id,
              user_id: user.id,
              label: user.label,
              days: days
            });
          }
        });

        let groupRow = this.table.rows.find(
          g => g.group_id == group.id && g.user_id == 0
        );

        groupRow.days = [];

        let groupUserRows = this.table.rows.filter(u => u.group_id == group.id);

        //go through all days and calculate totals by groups
        daysList.forEach(day => {
          let dayTotal = 0;

          groupUserRows.forEach(u => {
            u.days.forEach(i => {
              if (i.date == day) {
                dayTotal += +i.total || 0;
              }
            });
          });

          groupRow.days.push({ date: day, total: dayTotal.toFixed(2) });
        });
      });

      this.table.rows.forEach(row => {
        row.total = 0;
        row.days.forEach(day => {
          row.total += +day.total > 0 ? +day.total : 0;
        });

        row.total = row.total.toFixed(2);
      });
    }
  },
  watch: {
    rawData(newValue) {
      //console.log("newValue", JSON.stringify(newValue));
      this.prepareData(newValue);
    }
  }
};
</script>

<style></style>
